import {
	Center,
	Divider,
	Group,
	Table as MantineTable,
	Pagination,
	Progress,
	Select,
	Text,
	Title,
	rem,
} from '@mantine/core';
import type { CSSProperties } from '@mantine/core';
import { useOs } from '@mantine/hooks';
import { flexRender } from '@tanstack/react-table';
import type { Row, RowData, Table } from '@tanstack/react-table';
import sum from 'lodash-es/sum';
import { memo, useMemo } from 'react';

import { SortAscIcon, SortDescIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

import type { TableVariant } from '../types';

export const PAGINATION_OPTIONS = [
	{ value: '25', label: '25' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
] as const;

export function DataTable<T extends RowData>({
	table,
	loading = false,
	hidePageSize = false,
	hidePagination = false,
	hideItemCount = true,
	itemCountLabel = undefined,
	variant = 'apple-table',
	minWidth,
	renderEmpty,
}: {
	table: Table<T>;
	loading: boolean;
	hidePageSize?: boolean;
	hidePagination?: boolean;
	hideItemCount?: boolean;
	itemCountLabel?: string;
	minWidth?: CSSProperties['minWidth'];
	variant?: TableVariant;
	renderEmpty?: () => React.ReactNode;
}) {
	const { t } = useTranslation('controls');
	const noRows = table.getRowModel().rows.length === 0;

	return (
		<>
			{!loading && noRows ? (
				<Center h='100%'>
					{renderEmpty ? (
						renderEmpty()
					) : (
						<Title mt='xl' order={3}>
							{t('dataTable.empty')}
						</Title>
					)}
				</Center>
			) : (
				<>
					<MantineTable.ScrollContainer
						minWidth={minWidth ?? table.getTotalSize()}
						maw='100%'
						h='100%'
					>
						<TableBody table={table} variant={variant} loading={loading} />
					</MantineTable.ScrollContainer>
					<TablePagingButtons
						table={table}
						hidePageSize={hidePageSize}
						hidePagination={hidePagination}
						pageSizeLabel={t('dataTable.pageSize')}
						hideItemCount={hideItemCount}
						itemCountLabel={itemCountLabel ?? t('dataTable.itemCountLabel')}
					/>
				</>
			)}
		</>
	);
}

export const TableBody = memo(function TableBody<TRow extends RowData>({
	table,
	striped = true,
	variant = undefined,
	loading = false,
}: {
	table: Table<TRow>;
	striped?: boolean;
	variant?: TableVariant;
	loading?: boolean;
}) {
	const headerGroups = table.getHeaderGroups();
	const headerCount = useMemo(() => sum(headerGroups.map(x => x.headers.length)), [headerGroups]);

	return (
		<MantineTable
			striped={striped}
			highlightOnHover
			stickyHeader
			variant={variant}
			// w={table.getTotalSize()}
		>
			<MantineTable.Thead bg='var(--apple-color-table-header-bg)'>
				{table.getHeaderGroups().map(headerGroup => (
					<MantineTable.Tr key={headerGroup.id}>
						{headerGroup.headers
							.filter(x => !x.column.columnDef.meta?.hideColumn)
							.map(header => (
								<MantineTable.Th
									key={header.id}
									colSpan={header.colSpan}
									w={header.getSize()}
									pos='relative'
									onClick={header.column.getToggleSortingHandler()}
									style={{
										textWrap: 'nowrap',
										cursor: header.column.getCanSort() ? 'pointer' : 'auto',
										userSelect: 'none',
										paddingRight: header.column.getCanSort()
											? rem(32)
											: undefined,
									}}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
									<Group
										gap={0}
										pos='absolute'
										top='0'
										right={0}
										h='100%'
										justify='end'
										align='center'
									>
										{{
											asc: <SortAscIcon />,
											desc: <SortDescIcon />,
											false: null,
										}[header.column.getIsSorted() as string] ?? null}
										{header.column.getIsLastColumn() ? null : (
											<Divider
												size='xs'
												orientation='vertical'
												c='white'
												my={6}
												onMouseDown={header.getResizeHandler()} // for desktop
												onTouchStart={header.getResizeHandler()} // for mobile
												onDoubleClick={() => header.column.resetSize()}
												style={{
													cursor: 'col-resize',
													userSelect: 'none',
													touchAction: 'none',
													transform:
														table.options.columnResizeMode ===
															'onEnd' && header.column.getIsResizing()
															? `translateX(${
																	(table.options
																		.columnResizeDirection ===
																	'rtl'
																		? -1
																		: 1) *
																	(table.getState()
																		.columnSizingInfo
																		.deltaOffset ?? 0)
																}px)`
															: '',
												}}
											/>
										)}
									</Group>
								</MantineTable.Th>
							))}
					</MantineTable.Tr>
				))}
				<MantineTable.Tr>
					<MantineTable.Th
						colSpan={headerCount}
						p={0}
						h={loading ? 3 : 0}
						style={{
							transition: 'height 0.5s ease',
						}}
					>
						<Progress
							radius='xl'
							size='xs'
							value={100}
							animated
							striped
							display={loading ? undefined : 'none'}
						/>
					</MantineTable.Th>
				</MantineTable.Tr>
			</MantineTable.Thead>
			<MantineTable.Tbody>
				{table.getRowModel().rows.map(row => (
					<TableRow key={row.id} row={row} />
				))}
			</MantineTable.Tbody>
		</MantineTable>
	);
});
TableBody.displayName = 'TableBody';

const TableRow = memo(function TableRow<TRow extends RowData>({ row }: { row: Row<TRow> }) {
	return (
		<MantineTable.Tr key={row.id}>
			{row
				.getVisibleCells()
				.filter(cell => !cell.column.columnDef.meta?.hideColumn)
				.map(cell => (
					<MantineTable.Td key={cell.id} w={cell.column.getSize()}>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</MantineTable.Td>
				))}
		</MantineTable.Tr>
	);
});
TableRow.displayName = 'TableRow';

function TablePagingButtons<TRow extends RowData>({
	table,
	pageSizeLabel,
	hidePageSize,
	hidePagination,
	hideItemCount,
	itemCountLabel,
}: {
	table: Table<TRow>;
	pageSizeLabel: string;
	hidePageSize: boolean;
	hidePagination: boolean;
	hideItemCount: boolean;
	itemCountLabel: string;
}) {
	const currentOS = useOs();
	const isMobile = currentOS === 'ios' || currentOS === 'android';

	return (
		<Group justify='end' align='center' p='lg' pb={isMobile ? '50px' : 'lg'}>
			{!hidePageSize && (
				<>
					<Text>{pageSizeLabel}</Text>
					<Select
						data={PAGINATION_OPTIONS}
						w={rem(100)}
						checkIconPosition='right'
						value={table.getState().pagination.pageSize.toString()}
						onChange={value => value && table.setPageSize(Number(value))}
					/>
				</>
			)}
			{!hidePagination && (
				<Pagination
					size='sm'
					total={table.getPageCount()}
					value={table.getState().pagination.pageIndex + 1}
					onChange={pageNumber => table.setPageIndex(pageNumber - 1)}
				/>
			)}

			{!hideItemCount && <Text>{`(${table.getRowCount()} ${itemCountLabel})`}</Text>}
		</Group>
	);
}
