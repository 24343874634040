import { useMantineTheme } from '@mantine/core';
import type { AppShellAsideConfiguration } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import { useEffect } from 'react';

import type { SidebarToggleButtonProps } from '@apple/ui/layouts/components/sidebar-toggle-button';

type RightSidebarState = {
	props: Required<AppShellAsideConfiguration>;
	toggleButtonProps?: {
		labels?: SidebarToggleButtonProps['labels'];
		icons?: SidebarToggleButtonProps['icons'];
	};
	hidden: boolean;
};

export const rightSidebarStore = new Store<RightSidebarState>({
	props: {
		width: 380,
		breakpoint: 'lg',
		collapsed: {
			desktop: false,
			mobile: true,
		},
	},
	hidden: true,
});

export function toggleRightSidebar() {
	rightSidebarStore.setState(prev => ({
		...prev,
		props: {
			...prev.props,
			collapsed: {
				desktop: !prev.props.collapsed.desktop,
				mobile: !prev.props.collapsed.mobile,
			},
		},
	}));
}

export function useRightSidebarProps() {
	return useStore(rightSidebarStore, x => (!x.hidden ? x.props : undefined));
}

export function useRightSidebarToggleButtonProps() {
	return useStore(rightSidebarStore, x => x.toggleButtonProps);
}

export function useRightSidebarCollapsed() {
	const theme = useMantineTheme();
	const collapsedState = useStore(rightSidebarStore, x => x.props.collapsed);
	const hidden = useStore(rightSidebarStore, x => x.hidden);
	const isSmallerThanBreakpoint = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`, false, {
		getInitialValueInEffect: false,
	});

	return {
		isCollapsed:
			(isSmallerThanBreakpoint ? collapsedState.mobile : collapsedState.desktop) ?? false,
		toggle: toggleRightSidebar,
		hidden,
		isSmallerThanBreakpoint,
	};
}

export function useRightSidebarSetComponent({
	toggleButtonProps,
}: {
	toggleButtonProps?: RightSidebarState['toggleButtonProps'];
}) {
	useEffect(() => {
		rightSidebarStore.setState(prev => ({
			...prev,
			toggleButtonProps,
			hidden: false,
		}));

		return () => {
			rightSidebarStore.setState(prev => ({
				...prev,
				toggleButtonProps: undefined,
				hidden: true,
			}));
		};
	}, [toggleButtonProps]);
}
